@import 'react-time-picker/dist/TimePicker.css';
@import 'react-clock/dist/Clock.css';

.react-time-picker {
  height: 100% !important;
}

.react-time-picker__wrapper {
  border: 1px solid var(--chakra-colors-neutral-400) !important;
  border-radius: 4px;
}

.react-time-picker__inputGroup select {
  cursor: pointer;
}

.react-time-picker__inputGroup__hour,
.react-time-picker__inputGroup__minute {
  padding: 0 0.5rem 0 0.5rem;
}

.react-time-picker__inputGroup__amPm {
  border-left: 1px solid var(--chakra-colors-neutral-400);
}

.react-time-picker__inputGroup__amPm:hover {
  background: var(--chakra-colors-neutral-100);
}
